// config.js

const getEnvFromHref = (href) => {
  if (href.startsWith('http://localhost:3001'))       return 'LOCALDEV';
  if (href.includes  ('.ngrok.io'))                   return 'DEV';
  if (href.startsWith('http://localhost'))            return 'DEV';
  if (href.startsWith('https://auth.dev.id-go.com'))  return 'DEV';
  if (href.startsWith('https://auth.uat.id-go.com'))  return 'UAT';
  if (href.startsWith('https://auth.id-go.com'))      return 'PROD';

  console.error(`IdgoAuthComponent: config.js - Unable to derive environment from href: ${href}`);
  return undefined;
};

const g_env = getEnvFromHref(window.location.href);
console.info(`IdgoAuthComponent: config.js Environment: ${g_env}`);

const g_envConfig =
{ 'LOCALDEV': { IDENTITY_SERVICE    : 'http://localhost:3000'
              }
, 'DEV'     : { IDENTITY_SERVICE    : 'https://devapi.id-go.com/service-identity'
              }
, 'UAT'     : { IDENTITY_SERVICE    : 'https://uatapi.id-go.com/service-identity'
              }
, 'PROD'    : { IDENTITY_SERVICE    : 'https://prodapi.id-go.com/service-identity'
              }
};

export const ctxValue = (key) => {

  if (key === 'ENV') return g_env;

  if (!(g_env in g_envConfig)) {
    console.error('IdgoAuthComponent: env <' + g_env + '> not defined in relying_party_env_cfg')
    return;
  }

  if (!(key in g_envConfig[g_env])) {
    console.error('IdgoAuthComponent: key <' + key + '> not defined in relying_party_env_cfg['+g_env+']')
    return;
  }

  return g_envConfig[g_env][key];
};