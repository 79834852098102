import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

// eslint-disable-next-line
import IdgoAuthComponent from './IdgoAuthComponent';

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App tab="home" {...window.xprops} />);
