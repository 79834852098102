let session = undefined;

const logout = () => {
  session = undefined;
};

const parseJWT = (token) => {
  try {
    return JSON.parse(window.atob(token.split(".")[1]));
  } catch(e) {
    return null;
  }   
};

export const setAccessToken = (token, usedFido) => {
  session = {token:token, usedFido:usedFido};
};

export const tokenVerify = () => {
  if (!session) return;
  const decodedJWT = parseJWT(session.token);
  if(decodedJWT.exp * 1000 < Date.now()) {
    logout();
  } else {
    return session.token;
  }
};