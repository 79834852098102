import React from 'react';
import { Button } from 'react-bootstrap';
import { startAuthentication } from '@simplewebauthn/browser';

//Utils
import { setAccessToken } from './utils/session';
import 
{ generateAuthOptions
, verifyAuthResponse
, getAuthRequestInfo
, verifyResponse
, getVerificationRequest
} from './utils/services';

function useXProps() {
  const [xprops, setXProps] = React.useState(window.xprops);
  React.useEffect(() => {
    if (!window?.xprops?.onProps) return;
    window.xprops.onProps((props) => {
      setXProps({ ...props });
    });
  }, []);
  return xprops;
}

function App(props) {
  const [ processing, setProcessing ] = React.useState(false);
  const uxp         = useXProps();
  const disabled    = uxp?.disabled || false;
  const buttonStyles= uxp?.buttonStyles;
  const buttonText  = uxp?.buttonText;
  const loginValue  = uxp?.loginValue;

  const processAuthenticationApproval = async ({authKey, customCode}) => {
    const resultAuthRequest = await getAuthRequestInfo(authKey, customCode);
    if (resultAuthRequest?.status===404) {
      return {code:11, message:resultAuthRequest?.message};
    }

    if (resultAuthRequest?.status!==200) {
      return {code:12, message:resultAuthRequest?.message};
    }

    const authenticationOptsResp = await generateAuthOptions(resultAuthRequest.data?.notificationValue);
    if (authenticationOptsResp?.status===500) {
      return {code: authenticationOptsResp.code, message: authenticationOptsResp.message};
    }

    // FIDO marker cookie NOT present (Assuming this means there is not a Passkey on this device)
    if (!authenticationOptsResp?.data?.hasFidoMarker) {
      return {code: 16, message: `no fido marker cookie - assuming no Passkey`};
    }

    // Pre-enrolled customers will not have gone through a device check yet, so this flag is used to show this alternative message to pre-enrolled users
    if (authenticationOptsResp?.data?.isFirstTimePreEnrolled === 1) {
      return {code: 4, message: `auth failed this user has not completed enrollment`};
    }

    // Server has no FIDO credentials for this login, initate SMS verification code
    if (authenticationOptsResp?.data?.authOptions?.allowCredentials?.length === 0) {
      return {code:5, message: `auth failed this user has not setup a Passkey`};
    }

    let authenticationResp;
    try {
      // Pass the options to the authenticator and wait for a response
      authenticationResp = await startAuthentication(authenticationOptsResp.data.authOptions);
    } catch (err) {
      // An error is thrown if there is no FIDO key on this device, OR if the user selects Cancel from the Platform Authenticator dialog
      return {code:6, message: `auth aborted due to startAuthentication() error - possibly no FIDO key on this device`};
    }

    const verifyAuthenticationResp = await verifyAuthResponse(resultAuthRequest.data?.notificationValue, authenticationResp);
    if (authenticationOptsResp?.status===500) {
      return {code: verifyAuthenticationResp.code, message: verifyAuthenticationResp.message};
    }

    // Show UI appropriate for the `verified` status
    if ( ! verifyAuthenticationResp || ! verifyAuthenticationResp.data ) {
      return {code:9, message: `auth Missing response from verifyAuthResponse()`};
    }
    if ( ! verifyAuthenticationResp.data.verified ) {
      return {code:10, message: `auth verifyAuthResponse() returned NOT verified`};
    }
    setAccessToken(verifyAuthenticationResp.data.token, true);

    const responseGetVR = await getVerificationRequest(resultAuthRequest.data?.subdomain, customCode);
    if (responseGetVR?.status===401) {
      return {code:11, message: `Authentication approval rights been revoked`};
    }

    if (responseGetVR?.status!==200) {
      return {code:12, message: `System issue`};
    }

    const vr = responseGetVR?.data?.verificationRequest;
    if (!vr) {
      return {code:13, message: `This authentication request has been completed`};
    }

    if (vr?.status) {
      return {code:14, message: `This authentication request has been completed`};
    }

    const responseVR = await verifyResponse(resultAuthRequest?.data?.subdomain, vr.verificationRequestId, 'pass');
    if (responseVR?.status!==201) {
      return {code:15, message: `System issue`};
    }

    return {code:0, message: `Success`};
  };

  const handleAuth = async () => {
    setProcessing(true);
    const devTestUserAuthUrl = 'https://dev.id-go.com/a/authKey/customCode';
    if (!props.onClick) {
      console.warn(`IdgoAuthComponent: props.onClick() missing, returning test userAuthUrl: ${devTestUserAuthUrl}`);
    }
    const authInfo = (props?.onClick) ? await props.onClick(loginValue) : { userAuthUrl: devTestUserAuthUrl, verificationRequestId: 88888888};
    if (!authInfo?.userAuthUrl) {
      console.warn(`IdgoAuthComponent: userAuthUrl missing - aborting authentication`);
      setProcessing(false);
      return
    }

    if (!props.onAuthenticationResult) {
      console.warn(`IdgoAuthComponent: props.onAuthenticationResult() missing`);
    }

    const parts = authInfo.userAuthUrl.split('/');
    const customCode = parts.pop();
    const authKey = parts.pop();

    if (!customCode || !authKey) {
      const code = 1;
      const message = `Invalid userAuthUrl (code:${code})`;
      console.info(`IdgoAuthComponent: handleAuth() result: {code: ${code}, message: '${message}'}`);
      if (props?.onAuthenticationResult) props.onAuthenticationResult(code, message, authInfo.userAuthUrl, authInfo.verificationRequestId);
      setProcessing(false);
      return;
    }

    const result = await processAuthenticationApproval({authKey, customCode});
    if (props?.onAuthenticationResult) props.onAuthenticationResult(result.code, result.message, authInfo.userAuthUrl, authInfo.verificationRequestId);
    setProcessing(false);
  };

  const renderContent = () => {
    const styles = (buttonStyles) ? JSON.parse(buttonStyles) : {};
    return <>
      <Button style={styles} onClick={handleAuth} disabled={disabled || processing} type='submit'>
        {buttonText || 'Sign In'}
      </Button>
    </>
  };

  return (
    <>
      { renderContent() }  
    </>
  );
};

export default App;
