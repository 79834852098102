import axios from 'axios';

import { ctxValue } from './config';
import { tokenVerify } from './session';

// FIDO apis

export async function generateAuthOptions(loginValue) {
  const url = ctxValue('IDENTITY_SERVICE') + `/webauth/generateAuthOptions/mobile/${loginValue}/none`;
  try {
    // withCredentials causes Cookies to be passed to the service in this HTTP request
    const response = await axios.get(url, { withCredentials: true });
    return response;
  } catch (err) {
    if (err?.response?.status===404) {
      console.warn(`IdgoAuthComponent: generateAuthOptions() returned status:404, message:'account not found'`);
      return {status: 500, code: 2, message: `account not found`};
    }
    console.error(`IdgoAuthComponent: generateAuthOptions() error: ${err}`);
    return {status: 500, code: 3, message: `Authentication aborted due to generateAuthOptions error message: ${err}`};
  }
};

export async function verifyAuthResponse(loginValue, authenticationResp) {
  const url = ctxValue('IDENTITY_SERVICE') + `/webauth/verifyAuthResponse/mobile/${loginValue}/none`;
  try {
    // withCredentials causes Cookies to be passed to the service in this HTTP request
    const response = await axios.post(url, authenticationResp, { withCredentials: true });
    return response;
  } catch (err) {
    if (err?.response?.status === 401) {
      console.warn(`IdgoAuthComponent: verifyAuthResponse() returned status:401, message:'credential did not match'`);
      // credential did not match
      return {status: 500, code:7, message: `auth aborted due to verifyAuthResponse() error`};
    }
    console.error(`IdgoAuthComponent: verifyAuthResponse() error: ${err}`);
    return {status: 500, code:8, message:`auth aborted due to verifyAuthResponse() error`};
  }
};

//Lookup Auth Request information by authKey and customCode

export async function getAuthRequestInfo(authKey, customCode) {
  return axios.get(
    ctxValue('IDENTITY_SERVICE') + `/account/auth/${authKey}/${customCode}`,
  ).then(result => { return result; }
  ).catch(err => {
    if (err?.response?.status===404) {
      return {status:404, message:'Auth request expired'};
    }
    console.error(`IdgoAuthComponent: getAuthRequestInfo() - ${err}`);
    return {status:500, message:'System error fetching authentication information'};
  });
};

// Idenitty business logic apis

export async function getVerificationRequest(subdomain, verifyCounter) {
  const token = tokenVerify();
  try {
    const response = await axios.get(
      ctxValue('IDENTITY_SERVICE') + `/verifyUser/verificationRequest/v1/${subdomain}/${verifyCounter}`,
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
    return response;
  } catch (err) {
    if (err?.response?.status===401) {
      console.warn(`IdgoAuthComponent: getVerificationRequest() returned status:401, message:'verification request not found'`);
      return {...err.response.data, status:401};
    }
    console.error(`IdgoAuthComponent: getVerificationRequest() - ${err}`);
    return {status:500, message:'System issue'};
  }
};

export async function verifyResponse(subdomain, verificationRequestId, status) {
  const token = tokenVerify();
  try {
    return axios.post(
      ctxValue('IDENTITY_SERVICE') + `/verifyUser/confirm/v1/${subdomain}`,
      { verificationRequestId, status },
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
  } catch (err) {
    console.error(`IdgoAuthComponent: verifyResponse() - ${err}`);
    return {status:500, message:'System issue'};
  }
};

// Persist log messages

export async function logMessage(level, message, loginValue) {
  const token = tokenVerify();
  try {
    return axios.post(
      ctxValue('IDENTITY_SERVICE') + '/account/logMessage',
      { level, message, userAgent: navigator?.userAgent, loginValue },
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
  } catch (err) {
    console.error(`IdgoAuthComponent: logMessage() - ${err}`);
    return {status:500, message:'System issue'};
  }
};